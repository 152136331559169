import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import SideBar from 'components/SideBar/SideBar';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getUserSession } from 'services/auth';
import { provinceSelector } from 'store/app/selectors';

interface PublicLayoutInterface {
  children: ReactNode;
  fixedHeader?: boolean;
  onMobileSearchClick?: any;
  toggleHeaderOnScrolling?: boolean;
}
const PublicLayout = ({
  children,
  fixedHeader,
  onMobileSearchClick,
  toggleHeaderOnScrolling = true,
}: PublicLayoutInterface) => {
  const sm = useMediaQuery({ maxWidth: 992 });
  const [open, setOpen] = useState(false);
  const toggleSideNav = () => {
    console.log('open', open);

    setOpen(!open);
  };

  return (
    <>
      <Header
        fixedHeader={fixedHeader}
        onMobileSearchClick={onMobileSearchClick}
        toggleHeaderOnScrolling={toggleHeaderOnScrolling}
        toggleSideNav={sm ? toggleSideNav : undefined}
      />
      {sm && open && <SideBar open={open} sm={sm} setOpen={setOpen} />}
      <div id="main-container">{children}</div>

      <Footer />
    </>
  );
};

export default PublicLayout;
