import React from 'react';
import PublicLayout from 'components/PublicLayout/PublicLayout';

const PrivacyPolicy: React.FC = () => {
    return (
        <PublicLayout>
            <div className="container mt-4">
                <h2>Privacy Policy</h2>
                <h3>1. Introduction</h3>
                <p>
                    Welcome to <strong>Motonexa</strong> ({'we,'} {'our,'} {'us'}). Your privacy is important to us,
                    and we are committed to protecting your personal information. This Privacy Policy
                    explains how we collect, use, and safeguard your information when you visit our website.
                </p>

                <h3>2. Information We Collect</h3>
                <ul>
                    <li><strong>Personal Information:</strong> Name, email address, phone number, and other details you provide when you register, subscribe, or contact us.</li>
                    <li><strong>Usage Data:</strong> Information about how you interact with our website, including IP address, browser type, and device information.</li>
                    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance your experience, analyze traffic, and personalize content.</li>
                </ul>

                <h3>3. How We Use Your Information</h3>
                <ul>
                    <li>To provide and improve our services</li>
                    <li>To respond to inquiries and customer support requests</li>
                    <li>To send promotional emails (if you opt-in)</li>
                    <li>To analyze website performance and user behavior</li>
                    <li>To comply with legal obligations</li>
                </ul>

                <h3>4. Sharing Your Information</h3>
                <p>We do not sell or rent your personal information. However, we may share your information with:</p>
                <ul>
                    <li>Service providers that help us operate our website and services</li>
                    <li>Legal authorities if required by law</li>
                    <li>Business partners with your consent</li>
                </ul>

                <h3>5. Data Security</h3>
                <p>
                    We implement appropriate security measures to protect your information from unauthorized
                    access, disclosure, or alteration. However, no method of transmission over the internet is completely secure.
                </p>

                <h3>6. Your Rights</h3>
                <p>Depending on your location, you may have the right to:</p>
                <ul>
                    <li>Access, update, or delete your personal information</li>
                    <li>Opt-out of marketing communications</li>
                    <li>Restrict or object to certain data processing</li>
                </ul>

                <h3>7. Third-Party Links</h3>
                <p>
                    Our website may contain links to third-party websites. We are not responsible for their privacy
                    practices, and we encourage you to read their privacy policies.
                </p>

                <h3>8. Changes to This Policy</h3>
                <p>
                    We may update this Privacy Policy from time to time. The latest version will be posted on our
                    website with the effective date.
                </p>

                <h3>9. Contact Us</h3>
                <p>
                    If you have any questions about this Privacy Policy, you can contact us at <strong>[Your Contact Email]</strong>.
                </p>
            </div>
        </PublicLayout>
    );
};

export default PrivacyPolicy;
