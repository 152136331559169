import { lazy } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop';
import { ROUTES } from 'routes/constants';
import DefaultRoute from 'routes/Default';
import ProtectedRoute from 'routes/ProtectedRoute';
import PublicRoute from 'routes/PublicRoute';
import OtpLogin from 'pages/OtpLogin/OtpLogin';
import PointHistory from 'pages/PointHistory/PointHistory';
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy';

// const AddServiceProviderBusiness = lazy(
//   () => import('pages/AddServiceProviderBusiness/AddServiceProviderBusiness'),
// );
const AddSupplierBusiness = lazy(() => import('pages/AddSupplierBusiness/AddSupplierBusiness'));
const CreateRFQ = lazy(() => import('pages/CreateRFQ/CreateRFQ'));
const Cart = lazy(() => import('pages/Cart/Cart'));
const ContactUs = lazy(() => import('pages/ContactUs/ContactUs'));
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const DetailPage = lazy(() => import('pages/DetailPage/DetailPage'));
const ForgotPin = lazy(() => import('pages/ForgotPin/ForgotPin'));
const ListingSupplier = lazy(() => import('pages/ListingSupplier/ListingSupplier'));
const Login = lazy(() => import('pages/Login/Login'));
const MyChats = lazy(() => import('pages/MyChats/MyChats'));
const MyOrders = lazy(() => import('pages/MyOrders/MyOrders'));
const OrderDetail = lazy(() => import('pages/OrderDetail/OrderDetail'));
const Profile = lazy(() => import('pages/Profile/Profile'));
const ResetPin = lazy(() => import('pages/ResetPin/ResetPin'));
// const Rfq = lazy(() => import('pages/Rfq/Rfq'));
// const RfqList = lazy(() => import('pages/RfqList/RfqList'));
const RfqRequestDetail = lazy(() => import('pages/RfqRequestDetail/RfqRequestDetail'));
const RfqResponseDetail = lazy(() => import('pages/RfqResponseDetail/RfqResponseDetail'));
// const RfqResponseList = lazy(() => import('pages/RfqResponseList/RfqResponseList'));
const SignUpBusiness = lazy(() => import('pages/SignUpBusiness/SignUpBusiness'));

const Logout = lazy(() => import('pages/Logout/Logout'));
const AboutUs = lazy(() => import('pages/AboutUs/AboutUs'));
const LiveFeed = lazy(() => import('pages/LiveFeed/LiveFeed'));
const VerifyOtp = lazy(() => import('pages/VerifyOtp/VerifyOtp'));
// const SignUp = lazy(() => import('pages/SignUp/SignUp'));
const AddNewServiceProviderBusiness = lazy(
  () => import('pages/AddNewServiceProviderBusiness/AddNewServiceProviderBusiness'),
);
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions/TermsAndConditions'));
const Disclaimer = lazy(() => import('pages/Disclaimer/Disclaimer'));
const CookiePolicy = lazy(() => import('pages/CookiePolicy/CookiePolicy'));
const AddRFQ = lazy(() => import('pages/AddRFQ/AddRFQ'));
const RFQListNew = lazy(() => import('pages/RFQListNew/RFQListNew'));
const RFQResponseListNew = lazy(() => import('pages/RFQResponseListNew/RFQResponseListNew'));
const DownloadPdf = lazy(() => import('pages/DownloadPdf/DownloadPdf'));

// Public routes
const Home = lazy(() => import('pages/Home/Home'));
// const AddSupplierBusiness = lazy(() => import('pages/AddSupplierBusiness/AddSupplierBusiness'));

// Protected routes

const Routes = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      {/* Always accessible */}
      <Route path={ROUTES.HOME} exact>
        <Home />
      </Route>
      <Route path={ROUTES.LIST_SUPPLIER} exact>
        <ListingSupplier />
      </Route>
      <Route path={ROUTES.DETAIL_SUPPLIER}>
        <DetailPage />
      </Route>
      <Route path={ROUTES.CONTACT_US}>
        <ContactUs />
      </Route>
      <Route path={ROUTES.ABOUT_US}>
        <AboutUs />
      </Route>
      <Route path={ROUTES.PRIVACY_POLICY}>
        <PrivacyPolicy />
      </Route>
      <Route path={ROUTES.TERMS_OF_SERVICE}>
        <TermsAndConditions />
      </Route>
      <Route path={ROUTES.DISCLAIMER}>
        <Disclaimer />
      </Route>
      <Route path={ROUTES.COOKIE_POLICY}>
        <CookiePolicy />
      </Route>

      {/* Public routes */}
      <PublicRoute path={ROUTES.LOGIN}>
        <Login />
      </PublicRoute>
      {/* <PublicRoute path={ROUTES.SIGNUP}>
        <SignUp />
      </PublicRoute> */}
      <PublicRoute path={ROUTES.REGISTER_BUSINESS}>
        <SignUpBusiness />
      </PublicRoute>
      <PublicRoute path={ROUTES.VERIFY_OTP}>
        <VerifyOtp />
      </PublicRoute>
      <PublicRoute path={ROUTES.LOGIN_WITH_OTP}>
        <OtpLogin />
      </PublicRoute>
      <PublicRoute path={ROUTES.FORGOT_PIN}>
        <ForgotPin />
      </PublicRoute>
      <PublicRoute path={ROUTES.RESET_PIN}>
        <ResetPin />
      </PublicRoute>

      {/* Protected routes */}
      <ProtectedRoute path={ROUTES.DASHBOARD} exact>
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.ADD_BUSINESS}>
        <AddSupplierBusiness />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.ADD_SERVICE_PROVIDER_BUSINESS}>
        <AddNewServiceProviderBusiness />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.CREATE_RFQ}>
        <CreateRFQ />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.ADD_RFQ}>
        <AddRFQ />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.CART}>
        <Cart />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.RFQ_LIST}>
        {/* <RfqList /> */}
        <RFQListNew />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.RFQ_DETAIL}>
        <RfqRequestDetail />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.RFQ_RESPONSE_LIST}>
        {/* <RfqResponseList /> */}
        <RFQResponseListNew />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.RFQ_RESPONSE_DETAIL}>
        <RfqResponseDetail />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.PROFILE}>
        <Profile />
      </ProtectedRoute>
      <ProtectedRoute exact path={ROUTES.ORDERS}>
        <MyOrders />
      </ProtectedRoute>
      <ProtectedRoute path={`${ROUTES.ORDERS}/:id`}>
        <OrderDetail />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.CHAT}>
        <MyChats />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.LIVE_FEED}>
        <LiveFeed />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.DOWNLOAD_PDF} exact>
        <DownloadPdf />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTES.POINT_HISTORY} exact>
        <PointHistory />
      </ProtectedRoute>
      <Route path={ROUTES.LOGOUT} exact>
        <Logout />
      </Route>
      {/* Default */}
      <Route path="*">
        <DefaultRoute />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
